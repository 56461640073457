import React , {useState, useEffect} from 'react'
import axios from 'axios'; 
import { useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import './styles.css'
import logo from './ponea-logo.png'

const BACKEND_URL=process.env.REACT_APP_PAYMENT_URL

const USDconversionRate = 117.8

function PaypalCheckout() {
    
    const {uuid} = useParams();

    const createOrder = async (data, actions) => {

        const resp = await axios.get(`${BACKEND_URL}/paypal/paypal_payment/${uuid}`)
        const resData = resp.data
        let amount = resData?.amount/USDconversionRate
        amount = amount.toFixed(2)
        const request = {
          purchase_units: [
            {
                amount: {
                    value: amount,
                    currency: "USD"
                },

                description: resData?.description,
                custom_id: uuid,
            },
          ]
        };
        return actions.order.create(request);
      }


    return (

            <div className='content'>
                <img src={logo} width="340" height="130"/>
                <PayPalScriptProvider options={{ "client-id": "AUgH5kJvbJpHgB50h2u56Bu2t8FqzTPwwqne8sam65k1UJgG8pzB9Mx-WfyEF4tvOn5PjeKZcco3bPDG" }}>
                    <PayPalButtons
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                const name = details.payer.name.given_name;
                                window.location.replace(`${BACKEND_URL}/pages/payment_done/`)
                            });
                        }}
                    />
                </PayPalScriptProvider>
            </div>
    )
}
export default PaypalCheckout
